import "../styles/About.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faCoffee, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faReact, faNodeJs, faSwift, faAws, faJsSquare, faSquareTumblr, faGoogle } from '@fortawesome/free-brands-svg-icons'

export const About = () => {
  return (
    <div className="container spacer" id="about">
      <div className="col-md-9 mx-auto">

        <div className="row">
          <h4 className="text-secondary decorated" data-aos="fade-up" data-aos-duration="1000">
            <span>Technologies I've been working with recently:</span></h4>

          <div className="col-md-8">
            <p className="mt-3 text-secondary" data-aos="fade-up" data-aos-duration="1000">
              Here are a few technologies I've been working with recently, each enhancing my skill set across
              various projects. I've focused on  Swift and React Native with Expo for mobile app development,
              creating smooth, user-friendly interfaces. Using Apollo Client with GraphQL, I've improved data management
              and enabled real-time updates in both mobile and web projects. Next.js has been essential for
              building server-rendered, SEO-optimized applications with strong scalability.
            </p>

            <p className="text-secondary" data-aos="fade-up" data-aos-duration="1200">
              On the backend, Node.js and Express have been key for developing robust APIs,
              while TypeScript has helped me build more reliable, maintainable code. Additionally,
              AWS and Google Cloud Platform have supported scalable solutions for
              database management, and serverless functions,
              allowing me to build versatile, user-centered applications across platforms.</p>


            <div className="row">
              <div className="col">
                <ul className="list-group" data-aos="fade-up" data-aos-duration="1200">
                  <li className="list-group-item"><FontAwesomeIcon icon={faJsSquare} /> React/NextJS</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faNodeJs} /> Node.js</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faSquareTumblr} /> TypeScript</li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group list-group" data-aos="fade-up" data-aos-duration="1200">
                  <li className="list-group-item"><FontAwesomeIcon icon={faMobileAlt} /> Flutter (Dart)</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faSwift} /> Swift</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faReact} /> Apollo/GraphQL</li>
                </ul>
              </div>

              <div className="col">
                <ul className="list-group list-group" data-aos="fade-up" data-aos-duration="1200">
                  <li className="list-group-item"><FontAwesomeIcon icon={faAws} /> AWS</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faGoogle} /> Firebase</li>
                  <li className="list-group-item"><FontAwesomeIcon icon={faDatabase} /> SQL/NoSQL</li>

                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-center">
            <img src={require('../assets/img/erwin_agpasa.png')} alt="me" className="me rounded img-fluid" />
            <small className="mt-3 text-secondary d-block">
              <a href="https://www.paypal.com/donate/?hosted_button_id=83X4VR23JDGK2" className="btn btn-outline-secondary" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faCoffee} /> Buy me a coffee
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}